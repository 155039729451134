import React, { useState, useEffect } from "react";
import axios from "axios";
export const Upisettings = () => {
  const backendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const backendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? backendLocalApiUrl : backendLiveApiUrl;
  const [upiSettings, setUpiSettings] = useState([]);
  const [message, setMessage] = useState("");

  // useEffect(() => {
  //   const fetchUpiSettings = async () => {
  //     try {
  //       const response = await axios.get(`${baseUrl}User/upisettings`);
  //       if (response.data.status === 'success') {
  //         setUpiSettings(response.data.upiSettings.map((setting, index) => ({
  //           ...setting,
  //           id: index 
  //         })));
  //       } else {
  //         console.error("Failed to fetch UPI settings");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching UPI settings:", error);
  //     }
  //   };

  //   fetchUpiSettings();
  // }, [baseUrl]);

  const handleAddUpi = () => {
    setUpiSettings([
      ...upiSettings,
      { id: Date.now(), upiId: "", isEnabled: false, minAmount: 0, maxAmount: 1000 }
    ]);
  };
  
  
  const handleRemoveUpi = async (id) => {
    try {
      await axios.delete(`${baseUrl}User/upisettings/${id}`);
      setUpiSettings(upiSettings.filter(setting => setting.id !== id));
      alert("UPI setting successfully removed.");
  
      fetchUpiSettings();
    } catch (error) {
      console.error("Error deleting UPI setting:", error);
      alert("Failed to remove UPI setting.");
    }
  };


  const fetchUpiSettings = async () => {
    try {
      const response = await axios.get(`${baseUrl}User/upisettings`);
      if (response.data.status === 'success') {
        setUpiSettings(response.data.upiSettings.map((setting, index) => ({
          ...setting,
          id: index 
        })));
      } else {
        console.error("Failed to fetch UPI settings");
      }
    } catch (error) {
      console.error("Error fetching UPI settings:", error);
    }
  };
  
  useEffect(() => {
    fetchUpiSettings();
  }, [baseUrl]);

  const handleToggleChange = (id) => {
    const updatedSettings = upiSettings.map(setting =>
      setting.id === id
        ? { ...setting, isEnabled: !setting.isEnabled } 
        : setting
    );
    setUpiSettings(updatedSettings);
  };

  const handleInputChange = (id, field, value) => {
    setUpiSettings(
      upiSettings.map(setting => 
        setting.id === id ? { ...setting, [field]: value } : setting
      )
    );
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}User/upisettings`, {
        upiSettings
      });
      if (response.data.status === 'success') {
        alert("Settings submitted successfully");
      } else {
        alert("Settings Not Submitted");
      }
    } catch (error) {
      console.error("Error submitting settings:", error);
      alert("Error submitting settings");
    }
  };

  return (
   
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="text-uppercase font-weight-bold my-3">UPI Settings</h3>
          <button type="button" className="btn btn-primary" onClick={handleAddUpi}>
            Add UPI
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {upiSettings.map((setting) => (
    <div key={setting.id} className="form-row align-items-center mb-2">
      <div className="form-group col-md-3">
        <input type="text" className="form-control" placeholder="Enter UPI ID" 
          value={setting.upiId} onChange={(e) => handleInputChange(setting.id, 'upiId', e.target.value)}/>
      </div>
      <div className="form-group col-md-2">
        <input type="number" className="form-control" placeholder="Min Amount" 
          value={setting.minAmount} onChange={(e) => handleInputChange(setting.id, 'minAmount', e.target.value)}/>
      </div>
      <div className="form-group col-md-2">
        <input type="number" className="form-control" placeholder="Max Amount" 
          value={setting.maxAmount} onChange={(e) => handleInputChange(setting.id, 'maxAmount', e.target.value)}/>
      </div>
      <div className="form-group col-md-2">
        <div className="custom-control custom-switch">
          <input type="checkbox" className="custom-control-input" id={`customSwitch${setting.id}`} 
            checked={setting.isEnabled} onChange={() => handleToggleChange(setting.id)} />
          <label className="custom-control-label" htmlFor={`customSwitch${setting.id}`}>
            {setting.isEnabled ? "On" : "Off"}
          </label>
        </div>
      </div>
      <div className="form-group col-md-2">
        <button type="button" className="btn btn-danger" onClick={() => handleRemoveUpi(setting._id)}> Remove</button>
      </div>
    </div>
  ))}
          <div className="form-row">
            <div className="form-group col-md-12">
              <button type="submit" className="btn btn-dark">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
